import React from "react";

import { useAddress, useContract, useContractRead, useContractWrite } from "@thirdweb-dev/react";

import { ethers } from "ethers";




export default function WinningBox () {

    const { contract } = useContract("0xAf70b00E544e3c9530583016fF85AdA140eb0759");
    const address = useAddress();
    const { data: getWinningsForAddress } = useContractRead(contract, "getWinningsForAddress", [address]);
    const { mutateAsync: WithdrawWinnings } = useContractWrite(contract, "WithdrawWinnings")

    const onWithdrawWinnings = async () => {
        try {
            const data = await WithdrawWinnings({});
            console.info("contract call successs", data);
        } catch (error) {
            console.error("contract call failure", error);
        }
    }

    return (
        <div>
            {getWinningsForAddress > 0 && (
                    <div className="max-w-md md:max-w-2xl lg:max-x-4xl mx-auto mt-5">
                        <button onClick={onWithdrawWinnings} className="p-5 bg-gradient-to-b from-orange-200 to-orange-500 animate-pulse text-center rounded-xl w-full">
                            <p className="font-bold">Bravo tu as gagné</p>
                            <p>Gains totaux : {ethers.utils.formatEther(getWinningsForAddress.toString())} MATIC</p>
                            <br />
                            <p className="font-semibold">Clique ici pour récupérer tes gains !</p>
                        </button>
                    </div>
                )}
        </div>
    )
}