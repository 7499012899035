import React from "react";

import { useContract, useContractRead } from "@thirdweb-dev/react";
import { ethers } from "ethers";

import CountDownTimer from "./Timer";



export default function NextDraw() {

    const { contract } = useContract("0xAf70b00E544e3c9530583016fF85AdA140eb0759");
    const { data: RemainingTickets } = useContractRead(contract, "RemainingTickets", []);
    const { data: CurrentWinningReward } = useContractRead(contract, "CurrentWinningReward", []);

    return (
        <div className="stats-container">
            <h1 className="text-5xl font-semibold text-center">Prochain Tirage</h1>
            <div className="flex justify-between p-2 space-x-2">
                <div className="stats">
                    <h2 className="text-sm">Total à gagner</h2>
                    <p className="text-xl">
                        {CurrentWinningReward && ethers.utils.formatEther(CurrentWinningReward.toString())} MATIC
                    </p>
                </div>
                <div className="stats">
                    <h2 className="text-sm">Tickets restants</h2>
                    <p className="text-xl">{RemainingTickets?.toNumber()}</p>
                </div>
            </div>
            {/* Temps avant le prochain tirage */}
            <div className="mt-5 mb-3">
                <CountDownTimer />
            </div>
        </div>
    )
}