import React from "react";
import WinningBox from "./components/WinningBox";
import GameHome from "./components/GameHome";

import { Typography } from "@material-tailwind/react";

import { useAddress } from "@thirdweb-dev/react";

import Login from "./components/Login";


export default function Resultat() {

    const address = useAddress();

    if (!address) return <Login />;

    return (
        <div>
            <div className="justify-center flex pt-10">
                <div style={{ textAlign: "center" }}>
                    <Typography variant="h4">JE GRAND VAINQUEUR DE LA PRÉCÉDENTE LOTERIE EST :</Typography>
                </div>
            </div>
            <GameHome />
            <WinningBox />
        </div>
    );
}
