import React from "react";

import { useContract, useContractRead, useContractWrite } from "@thirdweb-dev/react";

import { StarIcon, CurrencyDollarIcon, ArrowPathIcon, ArrowUturnDownIcon } from "@heroicons/react/24/outline";

import { ethers } from "ethers";



export default function AdminPanel() {

    const { contract } = useContract("0xAf70b00E544e3c9530583016fF85AdA140eb0759");
    const { data: operatorTotalCommission } = useContractRead(contract, "operatorTotalCommission", [])
    const { mutateAsync: DrawWinnerTicket } = useContractWrite(contract, "DrawWinnerTicket")
    const { mutateAsync: restartDraw } = useContractWrite(contract, "restartDraw")
    const { mutateAsync: RefundAll } = useContractWrite(contract, "RefundAll")
    const { mutateAsync: WithdrawCommission } = useContractWrite(contract, "WithdrawCommission")


    const handleDrawWinnerTicket = async () => {
        try {
            const data = await DrawWinnerTicket({});
            console.info("contract call successs", data);
        } catch (error) {
            console.error("contract call failure", error);
        }
    }

    const handleRestartDraw = async () => {
        try {
            const data = await restartDraw({});
            console.info("contract call successs", data);
        } catch (error) {
            console.error("contract call failure", error);
        }
    }

    const handleRefundAll = async () => {
        try {
            const data = await RefundAll({});
            console.info("contract call successs", data);
        } catch (error) {
            console.error("contract call failure", error);
        }
    }

    const handleWithdrawCommission = async () => {
        try {
            const data = await WithdrawCommission({});
            console.info("contract call successs", data);
        } catch (error) {
            console.error("contract call failure", error);
        }
    }

    return (
        <div className="mx-auto max-w-screen-xl px-6 py-3 pt-7">
            <div className="text-center px-5 py-3 rounded-md border-[#023047] border">
                <h2 className="font-bold">Contrôles d'administrateur</h2>
                <p className="mb-5">Commission à récupérer : {operatorTotalCommission && ethers.utils.formatEther(operatorTotalCommission.toString())} MATIC</p>

                <div className="flex flex-col space-y-2 md:flex-row md:space-y-0 md:space-x-2">
                    <button className="admin-button" onClick={handleDrawWinnerTicket}>
                        <StarIcon className="h-6 mx-auto mb-2" />
                        Tirer un gagnant
                    </button>
                    <button className="admin-button" onClick={handleRestartDraw}>
                        <ArrowPathIcon className="h-6 mx-auto mb-2" />
                        Réinitialiser la lotterie
                    </button>
                    <button className="admin-button" onClick={handleRefundAll}>
                        <ArrowUturnDownIcon className="h-6 mx-auto mb-2" />
                        Rembourser les tickets
                    </button>
                    <button className="admin-button" onClick={handleWithdrawCommission}>
                        <CurrencyDollarIcon className="h-6 mx-auto mb-2" />
                        Récupérer les commissions
                    </button>
                </div>
            </div>
        </div>
    );
}