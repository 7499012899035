import React from "react";
import { useState } from "react";

import { useContract, useContractRead, useContractWrite } from "@thirdweb-dev/react";
import { ethers } from "ethers";




export default function BuyTickets() {


    const { contract } = useContract("0xAf70b00E544e3c9530583016fF85AdA140eb0759");
    const { data: RemainingTickets } = useContractRead(contract, "RemainingTickets", []);
    const { data: ticketPrice } = useContractRead(contract, "ticketPrice", []);
    const { data: ticketCommission } = useContractRead(contract, "ticketCommission", []);
    const { data: expiration } = useContractRead(contract, "expiration", []);
    

    const { mutateAsync: BuyTickets } = useContractWrite(contract, "BuyTickets")
    

    const [quantity, setQuantity] = useState(1);


    const handleBuyTicket = async () => {
        if (!ticketPrice) return;
        console.log("ticketPrice", ticketPrice);
        console.log("quantity", quantity);
        console.log("calcul", Number(ethers.utils.formatEther(ticketPrice.toString())) * quantity);
        try {
            const data = await BuyTickets({
                value: [{
                    value: ethers.utils.parseEther((Number(ethers.utils.formatEther(ticketPrice.toString())) * quantity).toString()),
                }],
            });
            console.info("contract call successs", data);
        } catch (error) {
            console.error("contract call failure", error);
        }
    };



    return (
        <div className="stats-container space-y-2">
            <div className="stats-container">
                <div className="flex justify-between items-center pb-2">
                    <h2>Prix d'un ticket</h2>
                    <p>
                        {ticketPrice && ethers.utils.formatEther(ticketPrice.toString())} MATIC
                    </p>
                </div>
                <div className="flex items-center space-x-2 bg-[#023047] border border-[#FFF] p-4">
                    <p>TICKETS</p>
                    <input
                        type="number"
                        className="flex w-full bg-transparent text-right outline-none"
                        min={1}
                        max={10}
                        value={quantity}
                        onChange={e => setQuantity(Number(e.target.value))}
                    />
                </div>

                <div className="space-y-2 mt-5">
                    <div className="flex items-center justify-between text-[#219EBC] text-sm italic font-extrabold">
                        <p>Coût total des tickets</p>
                        <p>
                            {ticketPrice && Number(ethers.utils.formatEther(ticketPrice.toString())) * quantity} MATIC
                        </p>
                    </div>
                    <div className="flex items-center justify-between text-[#219EBC] text-xs italic">
                        <p>Frais de service</p>
                        <p>
                            {ticketCommission && ethers.utils.formatEther(ticketCommission.toString())} MATIC
                        </p>
                    </div>
                    <div className="flex items-center justify-between text-[#219EBC] text-xs italic">
                        <p>+ Frais de réseau</p>
                        <p>TBC</p>
                    </div>
                </div>

                <button
                    onClick={handleBuyTicket}
                    disabled={expiration?.toString() < Date.now().toString() || RemainingTickets?.toNumber() === 0}
                    className="mt-5 w-full bg-[#FFB703] px-10 py-5 rounded-md text-white shadow-xl disabled:to-gray-600 disabled:from-gray-600 disabled:text-gray-100 disabled:cursor-not-allowed"
                >
                    Acheter
                </button>
            </div>
        </div>
    )
}