import { useAddress, useContract } from "@thirdweb-dev/react";


import HomeLottery from "./components/Home";
import Login from "./components/Login";



const Home = () => {
    const address = useAddress();
    const { isLoading } = useContract("0xAf70b00E544e3c9530583016fF85AdA140eb0759");

    if (isLoading) return (
        <div className="flex items-center justify-center h-screen">
            <div className="flex space-x-3 animate-pulse">
                <div className="w-4 h-4 bg-gray-500 rounded-full"></div>
                <div className="w-4 h-4 bg-gray-500 rounded-full"></div>
                <div className="w-4 h-4 bg-gray-500 rounded-full"></div>
            </div>
        </div>
    )

    if (!address) return <Login />;

    return (
        <div>
            <HomeLottery />
        </div>
    );
};

export default Home;
