import React from "react";

import { Web3Button } from "@thirdweb-dev/react";

import { Typography} from "@material-tailwind/react";
import Image from "../assets/logo.png";


export default function Login() {
    return (
        <div>
            <div className="justify-center flex" style={{
                alignItems: "center",
                padding: "10%"
            }}>
                <div style={{ textAlign: "center" }}>
                    <img src={Image} alt="logo" style={{ width: "300px" }} />
                    <Typography variant="h2" style={{ padding: "10px" }}>LUCKYBAY</Typography>
                    <Typography variant="p" style={{ padding: "10px" }}>Connectez votre walet</Typography>
                    <Web3Button />
                </div>
            </div>
        </div>
    )
}