import React from "react";

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { ThirdwebProvider } from "@thirdweb-dev/react";


import Home from "./Home";
import Jeu from "./Jeu";
import Resultat from "./Resultat";
import ComplexNavbar from "./components/Navbar";
import AdminPage from "./Admin";
import FooterWithSocialLinks from "./components/Footer";



function App() {
  return (
    <ThirdwebProvider
      activeChain="mumbai"
      clientId="bc9830bb769894ec051b7bfb3760902f" // You can get a client id from dashboard settings
    >
      <Router>
      <ComplexNavbar/>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/jeu" element={<Jeu />} />
          <Route path="/resultats" element={<Resultat />} />
          <Route path="/admin" element={<AdminPage />} />
        </Routes>
      <div className="pt-20">
        <FooterWithSocialLinks />
      </div>
      </Router>
    </ThirdwebProvider>
  )
}


export default App;