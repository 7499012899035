import React from "react";
import { Navbar, Collapse, Typography, IconButton, List, ListItem } from "@material-tailwind/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { useAddress, useDisconnect, useContract, useContractRead } from "@thirdweb-dev/react";
import logo from "../assets/logo.png";



function NavList() {

  const address = useAddress();
  const { contract } = useContract("0xAf70b00E544e3c9530583016fF85AdA140eb0759");
  const { data: isLotteryOperator } = useContractRead(contract, "lotteryOperator", [])
  const disconnect = useDisconnect();

  return (
    <List className="mt-4 mb-6 p-0 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1">
      <Typography
        as={Link}
        to="/jeu"
        variant="small"
        color="blue-gray"
        className="font-normal"
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4">
          JEU
        </ListItem>
      </Typography>
      <Typography
        as={Link}
        to="/resultats"
        variant="small"
        color="blue-gray"
        className="font-normal"
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4">
          RÉSULTATS
        </ListItem>
      </Typography>
      {isLotteryOperator === address && (
        <Typography
          as={Link}
          to="/admin"
          variant="small"
          color="blue-gray"
          className="font-normal"
        >
          <ListItem className="flex items-center gap-2 py-2 pr-4">
            ADMIN
          </ListItem>
        </Typography>
      )}
      {/* disconect button */}
      {address && (
        <Typography
          as="a"
          href="#"
          variant="small"
          color="blue-gray"
          className="font-normal"
        >
          <ListItem className="flex items-center gap-2 py-2 pr-4" onClick={disconnect}>
            DÉCONNEXION
          </ListItem>
        </Typography>
      )}
    </List>
  );
}



function ComplexNavbar() {
  const [openNav, setOpenNav] = React.useState(false);


  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  return (
    <Navbar className="mx-auto max-w-screen-xl px-6 py-3">
      <div className="flex items-center justify-between text-blue-gray-900">
        <div className="flex items-center justify-between">
          <img src={logo} alt="logo" width="50" height="50" />
          <Typography
            as="a"
            href="/"
            variant="h4"
            className="mr-4 cursor-pointer py-1.5 flex pl-4"
          >

            <div className="">LuckyBay</div>
          </Typography>
        </div>
        <div className="hidden lg:block">
          <NavList />
        </div>
        <IconButton
          variant="text"
          className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
          ripple={false}
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <XMarkIcon className="h-6 w-6" strokeWidth={2} />
          ) : (
            <Bars3Icon className="h-6 w-6" strokeWidth={2} />
          )}
        </IconButton>
      </div>
      <Collapse open={openNav}>
        <NavList />
      </Collapse>
    </Navbar>
  );
}


export default ComplexNavbar;