import React from "react";

import { Typography } from "@material-tailwind/react";
import gain from "../assets/gain-100k.jpg";




export default function HomeLottery() {

    return (
        <div className="mx-auto max-w-screen-xl px-6 py-3 pt-7">
            <div className="flex-1">
                {/* Défilement du dernier gagnant */}
                {/* <LastWinner /> */}
            </div>

            {/* Home informations */}
            <div>
                <Typography variant="h3" color="blue-gray" className="mb-2">Super loterie des fêtes de fin d'année</Typography>
                <div class="grid gap-5 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 pt-4">
                    <div class="col-span-2">
                        <img className="rounded-lg" src={gain} alt="tentez de gagner jusqu'à 100000 euros" />
                    </div>
                    <div class="bg-gray-200 rounded-lg">
                        <div className="p-8">
                            <p>On a tous rêvé du plus beau des cadeaux de Noël au pied de notre magnifique sapin. Et pourquoi pas un jackpot de 100 000 euros ? La Grande Loterie de Noel fait son apparition pour la première édition.</p>
                            <p className="pt-4">De quoi tenter même ceux qui ne croient plus au Père Noël…</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-20">
                <Typography variant="h3" color="blue-gray" className="mb-2">Super loterie des fêtes de fin d'année</Typography>
                <div class="grid gap-5 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-4 pt-4">
                    <div class="col-span-3">
                    <div className="p-8 bg-gray-200 rounded-lg">
                            <p>Sur LuckyBay le jeu devient une expérience transparente et équitable grâce à la blockchain. Notre engagement envers la transparence est ancré dans notre utilisation de la technologie décentralisée, chaque transaction étant enregistrée sur la blockchain, accessible à tous.</p>
                            <p className="pt-4">Pour renforcer votre confiance, notre code source est ouvert et vérifiable. Les smart contracts autonomes garantissent l'impartialité, éliminant toute influence humaine. En partenariat avec la ville EtherBay, notre loterie est conçue pour offrir une expérience sûre et fiable.</p>
                            <p className="pt-4">Jouez en toute confiance sur LuckyBay, où la technologie blockchain garantit l'équité à chaque tirage.</p>
                        </div>
                    </div>
                    <div class="">
                        
                    </div>
                </div>
            </div>
        </div>
    )
}