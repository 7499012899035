import React from "react";

import { useAddress, useContract, useContractRead } from "@thirdweb-dev/react";

import AdminPanel from "./components/Admin";
import Login from "./components/Login";


export default function AdminPage() {

    const address = useAddress();
    const { contract, isLoading } = useContract("0xAf70b00E544e3c9530583016fF85AdA140eb0759");
    const { data: isLotteryOperator} = useContractRead(contract, "lotteryOperator", [])

    if (isLoading) return (
        <div className="flex items-center justify-center h-screen">
            <div className="flex space-x-3 animate-pulse">
                <div className="w-4 h-4 bg-gray-500 rounded-full"></div>
                <div className="w-4 h-4 bg-gray-500 rounded-full"></div>
                <div className="w-4 h-4 bg-gray-500 rounded-full"></div>
            </div>
        </div>
    )

    if (!address) return <Login />;

    return (
        <div>
            {isLotteryOperator === address && (
                    <AdminPanel />
                )}
        </div>
    );
}