import React from "react";

import { useContract, useContractRead } from "@thirdweb-dev/react";

import Countdown from "react-countdown";



type Props = {
    hours: number;
    minutes: number;
    seconds: number;
    completed: boolean;
};


export default function CountDownTimer() {
    const { contract } = useContract("0xAf70b00E544e3c9530583016fF85AdA140eb0759");
    const { data: expiration } = useContractRead(contract, "expiration", []);
    const renderer = ({ hours, minutes, seconds, completed }: Props) => {
        if (completed) {
            return (
                <div>
                    <h2 className="text-white text-xl text-center animate-bounce">Achat des tickets terminé pour ce tirage </h2>
                </div>
            );
        } else if (!completed) {
            return (
                <div className="text-white text-sm mb-2">
                    <h3 className="text-white text-sm mb-2 italic">Temps restant</h3>
                    <div className="flex space-x-6">
                        <div className="flex-1">
                            <div className="countdown animate-pulse">{hours}</div>
                            <div className="countdown-label">heures</div>
                        </div>
                        <div className="flex-1">
                            <div className="countdown animate-pulse">{minutes}</div>
                            <div className="countdown-label">minutes</div>
                        </div>
                        <div className="flex-1">
                            <div className="countdown animate-pulse">{seconds}</div>
                            <div className="countdown-label">secondes</div>
                        </div>
                    </div>
                </div>
            );
        }
    };


    return (
        <div>
            <Countdown date={expiration?.toNumber() * 1000} renderer={renderer} />
        </div>
    )
}