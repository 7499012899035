import React from "react";

import { useContract, useContractRead } from "@thirdweb-dev/react";
import { ethers } from "ethers";


export default function GameHome() {

    const { contract } = useContract("0xAf70b00E544e3c9530583016fF85AdA140eb0759");
    const { data: lastWinner } = useContractRead(contract, "lastWinner", []);
    const { data: lastWinnerAmount } = useContractRead(contract, "lastWinnerAmount", []);


    return (
        <div className="mx-auto max-w-screen-xl px-6 py-3 pt-7">
            <div className="text-center px-5 py-3 rounded-md border-[#023047] border">
                <h4 className="font-bold">Dernier Gagnant : {lastWinner}</h4>
                <h4 className="font-bold">Précédent gains : {lastWinnerAmount && ethers.utils.formatEther(lastWinnerAmount?.toString())} MATIC</h4>
            </div>
        </div>
    )
}