import React from "react";
import NextDraw from "./components/NextDraw";
import BuyTickets from "./components/BuyTickets";

import { useAddress, useContract } from "@thirdweb-dev/react";

import Login from "./components/Login";



export default function Jeu() {

    const address = useAddress();
    const { isLoading } = useContract("0xAf70b00E544e3c9530583016fF85AdA140eb0759");

    if (isLoading) return (
        <div className="flex items-center justify-center h-screen">
            <div className="flex space-x-3 animate-pulse">
                <div className="w-4 h-4 bg-gray-500 rounded-full"></div>
                <div className="w-4 h-4 bg-gray-500 rounded-full"></div>
                <div className="w-4 h-4 bg-gray-500 rounded-full"></div>
            </div>
        </div>
    )

    if (!address) return <Login />;

    return (
        <div  className="mx-auto max-w-screen-xl px-6 py-3 pt-7">
            <div className="space-y-5 md:space-y-0 m-5 md:flex md:flex-rox items-start justify-center md:space-x-5">
                <NextDraw />
                <BuyTickets />
            </div>
        </div>
    );
}
